import api from '@/base/utils/request'

export const getEleCardConfig = (data) => {
  return api({ url: '/admin/cyc/settings/getEleCardConfig', method: 'post', data })
}

export const saveEleCardConfig = (data) => {
  return api({ url: '/admin/cyc/settings/saveEleCardConfig', method: 'post', data })
}

