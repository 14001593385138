<template>
  <div class="page" v-loading="loading">
    <el-form
      class="save-content"
      label-width="150px"
      :model="form"
      :rules="rules"
      ref="saveForm"
    >
      <p class="save-title" style="margin-bottom: 4px">基础信息</p>
      <el-form-item label="选择布局：" prop="design">
        <el-select
          style="min-width: 295px"
          v-model="form.design"
          placeholder="请选择"
          disabled
        >
          <el-option
            v-for="item in designOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="scroll-res" label="图片：" >
        <media-wall
          :limit="9 - form.images.length < 0 ? 0 : 10 - form.images.length"
          addText="上传图片"
          :width="150"
          :upload-type="['image']"
          :height="100"
          v-model="form.images"
          :draggable="true"
          @handleMediaSrc="handleMediaSrc"
        >
        </media-wall>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import MediaWall from '@/modules/common/components/MediaWall.vue'
// 接口
import { getEleCardConfig, saveEleCardConfig } from '../api/list.js'
export default {
  components: { FixedActionBar, MediaWall },
  data() {
    return {
      form: {
        design: -1,
        images: [],
      },
      loading: false,
      // 商品类型选项
      designOptions: [
        {
          value: -1,
          label: '默认',
        },
      ],
      // 校验规则
      rules: {
        design: [{ required: true, message: '请选择布局', trigger: 'change' }],

        images: [{ required: true, message: '请上传图片', trigger: 'blur' }],
      },
    }
  },
  methods: {
    // 上传图片
    handleMediaSrc(val) {
      console.log(val)
    },
    // 保存配置
    saveConfig(status) {
      let postData = {
        config: {
          ...this.form,
        },
      }

      // 保存商城数据
      this.$refs.saveForm.validate((valid) => {
        if (valid) {
          this.loading = true

          saveEleCardConfig(postData)
            .then((res) => {
              this.$message.success(res.msg)

              this.getDatail()

              this.loading = false
            })
            .catch((err) => {
              this.loading = false
            })
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    // 获取详情
    getDatail() {
      getEleCardConfig()
        .then((res) => {
          this.form = res.data

          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
  mounted() {
    this.getDatail()
  },

  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  padding-left: 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1;
  border-left: 3px solid #3479ef;
  margin-bottom: 32px;
}
</style>
